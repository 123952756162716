import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { StylesContext } from './App';
import { fieldRefs } from "./fieldRefs";
import { client } from "./client";
import { OWNER_BY_EXACT_NAME } from './queries';

export default function ConnectForm(props) {
  const { handleOnChangeGroup, ownerById, document_id, sid, updateStation, ownerFields } = props;
  const classes = useContext(StylesContext);
  const [open, setOpen] = useState(false);
  const [owner, setOwner] = useState("");
  const [ownerError, setOwnerError] = useState("");
  const [ownerData, setOwnerData] = useState({});

  const queryOwner = async(name) => {
    // setup and execute the query
    try {
        var q = OWNER_BY_EXACT_NAME(name);
        const data = await client.query(q);
        setOwnerData(data.data.data.length > 0 ? data.data.data[0] : {});
    } catch (error) {
        setOwnerData({});
        setOwnerError(error);
        console.log('Database read error: '+ error);
    }
  };

  const updateOwnerInForm = (ownerObject) => {
    let changeGroup = {};
    ownerFields.forEach(element => {
      const val = ownerObject ? ownerObject[fieldRefs[element].replace("owner.","")] : "";
      if (element.includes("display")) {  
        changeGroup[element] = val ? 1 : (val === null ? "" : 0);
      } else {
        changeGroup[element] = val;
      }
    });
    handleOnChangeGroup(changeGroup); 
  };

  function connectOwner() {
    if (document_id) {
      const updateOwnerInfo = async(ownerId) => {
        const ownerObject = await ownerById(ownerId);
        updateStation({id: document_id, updata: {owner: ownerObject}});   // update database
        updateOwnerInForm(ownerObject);                                   // update data displayed in form
      };
      updateOwnerInfo(ownerData.id);
     } else {
      // just update data displayed in form; station owner will be updated on form submit
      updateOwnerInForm(ownerData);
     }
  }
 
  function handleSearch() {
    queryOwner(owner);      
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOnOwnerChange(event) {
    setOwner(event.target.value);
  }

  return (
    <>
      <div style={{textAlign:"center"}}>
        <Button variant="outlined" size="small" onClick={handleClickOpen}>
            Connect owner
        </Button>
      </div>
      <Dialog open={open} maxWidth="lg" onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Owner Connection</DialogTitle>
        <DialogContent>
          <>
            <TextField
                id={"name"}
                name={"name"}
                value={owner}
                label={"Owner name"}
                margin="dense"
                variant="outlined"
                onChange={handleOnOwnerChange}
                autoFocus={true}
            />
            <Button variant="outlined" className={classes.submit} style={{marginTop:"0.7em"}} onClick={handleSearch}>
              Search
            </Button>
            {Object.keys(ownerData).length > 0 &&
              <Typography>
                Matched {ownerData.name} - {ownerData.affiliation || ownerData.id}
                <Button variant="outlined" size="small" className={classes.submit} onClick={connectOwner}>
                  Make connection
                </Button>
              </Typography>
            }
            {Object.keys(ownerData).length == 0 && 
              <Typography>
                No match
              </Typography>
            }
            {ownerError && 
              <p>Error searching for owner: {ownerError}</p>
            }
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
              Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}