import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Interface from './Interface';
import { useStyles } from './styles.js';
import createAuth0Client from '@auth0/auth0-spa-js'
import config from "./auth_config.json";

const onRedirectCallback = appState => {
  window.history.pushState({}, document.title, window.location.pathname)
  return
};

export const StylesContext = React.createContext();

const App = () => {
  const classes=useStyles()  
  const [ auth0Client, setAuth0 ] = useState()
  const [token, setToken] = useState()

  const logout = () => {
    auth0Client.logout({ returnTo: window.location.origin });
  };


  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        domain: config.domain,
        client_id: config.clientId,
        onRedirectCallback: onRedirectCallback,
        audience: "https://newa.rcc-acis.workers.dev/v0",
      })
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently()
        const response = await fetch(
          "https://newa.rcc-acis.workers.dev/v0/roles",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const roles = await response.json()
        setToken(roles.includes("metadata") ? token : undefined)
      } else {
        auth0FromHook.loginWithRedirect({redirect_uri: window.location.origin})
      }
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  /*
  // FOR DEVELOPMENT - SIMULATE AUTHORIZATION 
  // Comment out useEffect above and uncomment following useEffect
  
   useEffect(() => {
    setToken({key:1})
  }, [])
  */  


  return (
    <>
      <StylesContext.Provider value={classes}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Interface 
            token={token}
            logout={logout}
          />
        </LocalizationProvider>
      </StylesContext.Provider>
    </>
  )
}

export default App

/* previously withing Styles Context tags
AT TOP: import ServerSelector from './ServerSelector';

        <ServerSelector setClient={setClient} token={token} logout={logout} />
        {Object.keys(client).length > 0 &&
          <Interface />
        }
*/
