import React, { Fragment, useState, useEffect, useContext } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ViewOwner from './ViewOwner';
import { StylesContext } from './App';
import { client } from './client';
import { OWNERS_BY_NAME, OWNER_BY_EXACT_NAME } from './queries';

const OneOwnerQuery = (props) => {
    const { searchOwner } = props;
    const classes = useContext(StylesContext);
    const [ stationMeta, setStationMeta ] = useState({});
    const [ loading, setLoading ] = useState(false)
  
    const ownerByExactName = async(searchOwner) => {
        // setup and execute the query
        try {
            setLoading(true)
            const q = OWNER_BY_EXACT_NAME(searchOwner);
            const data = await client.query(q);
            if (data.data.data.length == 0) { throw "No owner with name " + searchOwner }
            setStationMeta(data.data.data[0]);
            setLoading(false);
        } catch (error) {
            setStationMeta({});
            console.log('Database owner read error: '+ error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchOwner.length) {
            setStationMeta({});
            ownerByExactName(searchOwner);
        }
    }, [searchOwner])

    return (
      <>
        {Object.keys(stationMeta).length > 0 &&
            <ViewOwner 
                results={stationMeta}
            />
        }
        {Object.keys(stationMeta).length == 0 && !loading && 
            <p className={classes.infomsg}>
                No owner associated with name of "{searchOwner}"
            </p>
        }
      </>
   );
}

// Get all owners and display those whose name contains the search string (srchFragment)
const AllOwnerQuery = (props) => {
  const { searchOwner } = props;
  const classes = useContext(StylesContext);
  const [ selectedOwner, setSelectedOwner ] = useState("");
  const [ allStationsData, setAllStationsData ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const ownersByName = async(searchOwner) => {
    // setup and execute the query
    try {
        setLoading(true)
        const q = OWNERS_BY_NAME(searchOwner);
        const data = await client.query(q);
        if (data.data.data.length == 0) { throw "No owner with name " + searchOwner }
        setAllStationsData(data.data.data);
        setLoading(false);
    } catch (error) {
        setAllStationsData([]);
        console.log('Database owner read error: '+ error);
        setLoading(false);
    }
  };

  useEffect(() => {
    if (searchOwner.length) {
        setAllStationsData({});
        ownersByName(searchOwner);
    }
  }, [searchOwner])

  return (
      <>
        {allStationsData.length > 0 && !selectedOwner &&
            <div className={classes.selectionsBox}>
                <b>Matches</b> (click to select):
                <List dense={true} disablePadding={true}>
                    {allStationsData.map((so) => (
                        <Fragment key={so.name}>
                            <ListItem key={so.name} button onClick={() => {setSelectedOwner(so.name)}}>
                                <ListItemText style={{margin:"0"}} primary={so.name} secondary={so.affiliation} />  
                            </ListItem>
                        </Fragment>
                    ))}
                </List>
            </div>
        }
        {allStationsData.length == 0 && !loading &&
            <p className={classes.infomsg}>
                Cannot find owners matching {searchOwner}
            </p>
        }
        {selectedOwner &&
            <OneOwnerQuery
                searchOwner={selectedOwner}
            />
        }
      </>
  );
}

export default function QueryOwner(props) {
    const { viewOwner, setViewOwner } = props;
    const classes = useContext(StylesContext);
    const [ searchOwner, setSearchOwner ] = useState("");
    const [ searchFragment, setSearchFragment ] = useState("");
    
    // allow submit using enter key, instead of requiring button click
    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            setViewOwner(true);
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
    }, [setViewOwner]);

    // new search
    useEffect(() => {
        if (!viewOwner) {
            setSearchOwner("");
            setSearchFragment("");
        }
    }, [viewOwner]);
    
    return (
        <>
            {!viewOwner &&
                <div className={classes.selectionsBox}>
                    <TextField
                        name="runquery"
                        label="Exact Name"
                        helperText="Faster"
                        value={searchOwner}
                        onChange={(e) => setSearchOwner(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        autoFocus={true}
                    />
                    <Typography style={{margin:"0 0.5em 0", lineHeight:"4em"}} display="inline">
                        - or -
                    </Typography>
                    <TextField
                        name="runquery"
                        label="Name Fragment"
                        helperText="Slower"
                        placeholder="Name contains ..."
                        value={searchFragment}
                        onChange={(e) => setSearchFragment(e.target.value)}
                        margin="dense"
                        variant="outlined"
                    />
                    <br />
                    <Button 
                        variant="contained" 
                        size="small" 
                        className={classes.submit} 
                        onClick={() => {setViewOwner(true);}}
                    >
                        Run search
                    </Button>
                </div>
            }
            {viewOwner && searchOwner !== "" &&
                <OneOwnerQuery
                    searchOwner={searchOwner}
                />
            }
            {viewOwner && searchFragment !== "" &&
                <AllOwnerQuery
                    searchOwner={searchFragment}
                />
            }
        </>
    )
}
