import React, { useEffect, useContext } from "react"
import { validationSchema, listItems, multilineList } from './validationSchema'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { StylesContext } from './App'

export default function RenderTextField(props) {
    const classes = useContext(StylesContext)
    const {field, ovalue, error, handleOnChange, values={}, disabled=false, useClassName="input"} = props
    const [value, setValue] = React.useState(ovalue)
    const [ffield, arrayField] = field.split(">")
    const vs = !arrayField ? validationSchema.fields[ffield] : validationSchema.fields[arrayField]._subType.fields[ffield]

    useEffect(() => {
        setValue(ovalue)
    }, [ovalue] )

    let selectList = []
    if (vs._type === "boolean") {
        selectList = [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ]
    } else if (listItems.hasOwnProperty(ffield)) {
        selectList = listItems[ffield]
    }

    function handleMyChange(event) {
		const value = event.target.value
		setValue(value)
        handleOnChange(event)
    }

	return (
        <>
            {(!vs._meta.show || values[vs._meta.show.when] === vs._meta.show.is) &&
                <>
                    <TextField
                        id={field}
                        name={field}
                        value={value}
                        label={vs._meta.label}
                        disabled={disabled}
                        required={vs._exclusive.hasOwnProperty('required') && vs._exclusive.required}
                        error={error.length > 0}
                        helperText={error}
                        margin="dense"
                        variant="outlined"
                        onChange={handleMyChange}
                        select={selectList.length > 0}
                        className={!vs._meta.show ? classes[useClassName]: classes.smallInput}
                        multiline={multilineList.includes(ffield)}
                    >
                        {selectList.length > 0 &&
                            selectList.map(option => (
                                <MenuItem key={option.value} value={option.value} selected={value === option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                    <br />
                </>
            }
        </>
	)
}