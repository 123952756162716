import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography';

export default function NotesDelete(props) {
  const { currentNotes, handleChange } = props
  const [open, setOpen] = useState(false)
  const [newNotes, setNewNotes] = useState(currentNotes)

  useEffect(() => {
    setNewNotes(currentNotes)
  },[currentNotes])

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleClickDelete(index) {
    const updatedNotes = [...newNotes.slice(0, index), ...newNotes.slice(index+1, newNotes.length)]
    setNewNotes(updatedNotes)
  }

  function handleOnSubmit() {
      handleChange({target: {name: "notes", value: newNotes}})
      setOpen(false)
  }

  return (
    <div style={{textAlign:"center", display:"inline-block", marginLeft:"3px"}}>
      <Button variant="outlined" size="small" onClick={handleClickOpen}>
        Delete note
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title" style={{paddingBottom: "0"}}>Notes Deletion</DialogTitle>
        <DialogContent>
            <List dense>
                {newNotes.map((anote, i) => (
                    <ListItem key={i} style={{paddingRight:'8em'}}>
                        <ListItemText primary={`${anote.entryDate}: ${anote.entryCreator}: ${anote.noteText}`} />
                        <ListItemSecondaryAction>
                            <Button variant="outlined" size="small" style={{backgroundColor: "#f4fef4"}} onClick={() => {handleClickDelete(i)}}>
                                Delete
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOnSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        <Typography variant="caption" align="right" style={{paddingRight:"8px", paddingLeft:"8px"}}>
          Main form must also be submitted
        </Typography>
      </Dialog>
    </div>
  )
}