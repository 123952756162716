import * as Yup from 'yup'

const stateListItems = [
	{ value: 'al', label: 'Alabama' },
	{ value: 'ct', label: 'Connecticut' },
	{ value: 'de', label: 'Delaware' },
	{ value: 'dc', label: 'District of Columbia' },
	{ value: 'ga', label: 'Georgia' },
	{ value: 'id', label: 'Idaho' },
	{ value: 'in', label: 'Indiana' },
	{ value: 'ia', label: 'Iowa' },
	{ value: 'il', label: 'Illinois' },
	{ value: 'ks', label: 'Kansas' },
	{ value: 'ky', label: 'Kentucky' },
	{ value: 'me', label: 'Maine' },
	{ value: 'md', label: 'Maryland' },
	{ value: 'ma', label: 'Massachusetts' },
	{ value: 'mi', label: 'Michigan' },
	{ value: 'mn', label: 'Minnesota' },
	{ value: 'mo', label: 'Missouri' },
	{ value: 'ne', label: 'Nebraska' },
	{ value: 'nh', label: 'New Hampshire' },
	{ value: 'nj', label: 'New Jersey' },
	{ value: 'ny', label: 'New York' },
	{ value: 'nc', label: 'North Carolina' },
	{ value: 'oh', label: 'Ohio' },
	{ value: 'pa', label: 'Pennsylvania' },
	{ value: 'ri', label: 'Rhode Island' },
	{ value: 'sc', label: 'South Carolina' },
	{ value: 'sd', label: 'South Dakota' },
	{ value: 'va', label: 'Virginia' },
	{ value: 'vt', label: 'Vermont' },
	{ value: 'ut', label: 'Utah'},
	{ value: 'wv', label: 'West Virginia' },
	{ value: 'wi', label: 'Wisconsin' },
	{ value: 'na', label: 'Not applicable' },
]

const provinceListItems = [
	{ value: 'on', label: 'Ontario' },
	{ value: 'qc', label: 'Quebec' }, 
	{ value: 'ab', label: 'Alberta' }, 
	{ value: 'bc', label: 'British Columbia' },
	{ value: 'mb', label: 'Manitoba' }, 
	{ value: 'nb', label: 'New Brunswick' }, 
	{ value: 'nl', label: 'Newfoundland and Labrador' }, 
	{ value: 'ns', label: 'Nova Scotia' }, 
	{ value: 'pe', label: 'Prince Edward Island' }, 
	{ value: 'sk', label: 'Saskatchewan' },
	{ value: 'nt', label: 'Northwest Territories' }, 
	{ value: 'nu', label: 'Nunavut' }, 
	{ value: 'yt', label: 'Yukon' },
]

export const listItems = {
	station_network: [
		{ value: 'newa', label: 'NEWA - RainWise' },
		{ value: 'nwon', label: 'NEWA - Onset' },
		{ value: 'icao', label: 'Airport'},
		{ value: 'njwx', label: 'NJ WxNet' },
		{ value: 'miwx', label: 'Enviroweather' },
		{ value: 'nysm', label: 'NYS Mesonet'},
		{ value: 'oardc', label: 'OARDC' },
		{ value: 'cu_log', label: 'CU Logger' },
		{ value: 'deos', label: 'DEOS' },
		{ value: 'ucc', label: 'Utah Mesonet'},
		{ value: 'winet', label: 'Wisconet'},
	],

	station_active_status: [
		{ value: "yes", label: 'Active'},
		{ value: "out", label: 'Inactive'},
		{ value: "disconnected", label: 'Disconnected'},
		{ value: "pending", label: 'Pending'},
		{ value: "research", label: 'Research'},
		{ value: "never", label: 'Never active'},
	],
	sensor_solrad_quality: [
		{ value: "ok", label: 'Approved'},
		{ value: "bad", label: 'Failed'},
		{ value: "pending", label: 'Pending' },
		{ value: "na", label: "Not applicable" }
	],
	loc_country: [
		{ value: 'us', label: 'US' },
		{ value: 'canada', label: 'Canada' },
	],
	owner_country: [
		{ value: 'us', label: 'US' },
		{ value: 'canada', label: 'Canada' },
	],
	station_state: stateListItems,
	owner_state: stateListItems,
	loc_province: provinceListItems,
	owner_province: provinceListItems,
	loc_timezone: [
		{ value: "-5", label: "Eastern" },
		{ value: "-6", label: "Central" },
		{ value: "-7", label: "Mountain" },
		{ value: "-8", label: "Pacific" },
		{ value: "na", label: "Unknown" },
	],
	station_brand: [
		{ value: 'rainwise', label: 'RainWise/KestrelMet' },
		{ value: 'onset', label: 'Onset' },
		{ value: 'sensatronics', label: 'Sensatronics' },
		{ value: 'other', label: 'Other/unknown' },
	],
	station_rw_model: [
		{ value: 'agrometmkiii', label: 'AgroMET MK III' },
		{ value: 'telemet', label: 'TeleMET' },
		{ value: 'km6000', label: 'KestrelMet 6000' },
		{ value: 'na', label: 'Other/unknown' },
	],
	depth: [		//sensor_temp_soil_depth, sensor_moisture_soil_depth
		{ value: 'unknown', label: 'Unknown' },
		{ value: '5cm', label: '5 cm / 2 inches' },
		{ value: '10cm', label: '10 cm / 4 inches' },
		{ value: '15cm', label: '15 cm / 6 inches' },
		{ value: '20cm', label: '20 cm / 8 inches' },
		{ value: '25cm', label: '25 cm / 10 inches' },
		{ value: '30cm', label: '30 cm / 12 inches' },
		{ value: '50cm', label: '50 cm / 20 inches' },
		{ value: '100cm', label: '100 cm / 40 inches' },
		{ value: '150cm', label: '150 cm / 60 inches' },
		{ value: '200cm', label: '200 cm / 80 inches' },
	],		
	cover: [		//sensor_temp_soil_cover, sensor_moisture_soil_cover
		{ value: 'unknown', label: 'Unknown' },
		{ value: 'bare', label: 'Bare soil' },
		{ value: 'grass', label: 'Grass' },
		{ value: 'sod', label: 'Sod' },
		{ value: 'fallow', label: 'Fallow' },
		{ value: 'brome grass', label: 'Brome grass' },
		{ value: 'straw mulch', label: 'Straw mulch' },
		{ value: 'grass muck', label: 'Grass muck' },
		{ value: 'bare muck', label: 'Bare muck' },
		{ value: 'corn res', label: 'Corn residue' },
		{ value: 'wheat res', label: 'Wheat residue' },
		{ value: 'bean res', label: 'Bean residue' },
	],
}

export const multilineList = [
	'owner_address', 
	'noteText',
	'owner_stations',
]

export function validatedate(inputText) {
	if (!inputText || inputText === "") {
		return true
	}
	const dateformat = /^(19|20)\d\d-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/
	// Match the date format through regular expression
	if (inputText.match(dateformat)) {
		const pdate = inputText.split('-')
		const yy = parseInt(pdate[0], 10)
		const mm = parseInt(pdate[1], 10)
		const dd = parseInt(pdate[2], 10)
		const ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31]
		if (mm === 1 || mm > 2) {
			return dd <= ListofDays[mm-1]
		} else if (mm === 2) {
			const lyear = (yy % 100 === 0) ? (yy % 400 === 0) : (yy % 4 === 0)
			return lyear === true ? dd <= 29 : dd < 29
		}
	} else {
		return false
	}
}
export function validatephone(inputText) {
	const phoneformat = /^[2-9]\d{2}-\d{3}-\d{4}[ ]{0,1}((x{1}\d{1,5})|cell|office){0,1}$/
	// Match the phone format through regular expression - can have extension, cell, or office 
	return (!inputText || inputText === "") || inputText.match(phoneformat)
}

export const validationSchema = Yup.object().shape({
	station_sid: Yup.string()
		.meta({label: "Station ID"})
		.notRequired(),
	station_nid: Yup.string()
		.meta({label: "Network ID"})
		.required('Required'),
	station_network: Yup.string()
		.meta({label: "Network"})
		.oneOf(listItems['station_network'].map((item) => item.value), "Not a valid network")
		.required('Required'),
	station_name: Yup.string()
		.meta({label: "Station name"})
		.min(2, 'Too Short!')
		.max(40, 'Too Long!')
		.required('Required'),
	station_active_status: Yup.string()
		.meta({label: "Status"})
		.default('pending')
		.oneOf(listItems['station_active_status'].map((item) => item.value), "Not a valid status")
		.required(),
	notes: Yup.array().default([]).of(Yup.object().shape({
		noteText: Yup.string()
			.meta({label: "Note text", show: {when: 'noteText', isnot: ""}})
			.notRequired(),
		entryDate: Yup.string()
			.meta({label: "Entry date", show: {when: 'entryDate', isnot: ""}})
			.notRequired()
			.test("validdate", "Invalid date - must be in the form yyyy-mm-dd", (value) => validatedate(value)),
		entryCreator: Yup.string()
			.meta({label: "Creator", show: {when: 'entryCreator', isnot: ""}})
			.notRequired(),
		})),
	loc_country: Yup.string()
		.meta({label: "Country"})
		.default("us")
		.oneOf(listItems['loc_country'].map((item) => item.value), "Not a valid country")
		.required('Required'),
	station_state: Yup.string()
		.meta({label: "State *"})
		.when('loc_country', {
			is: (val) => val === 'us',
			then: Yup.string().required("Required"),
			otherwise: Yup.string().notRequired(),
		}),
	loc_province: Yup.string()
		.meta({label: "Province *", show: {when: 'loc_country', is: 'canada'}})
		.when('loc_country', {
			is: (val) => val === 'canada',
			then: Yup.string().required("Required"),
			otherwise: Yup.string().notRequired(),
		}),
	loc_county: Yup.string()
		.meta({label: "County", show: {when: 'loc_country', is: 'us'}})
		.notRequired(),
	loc_city: Yup.string()
		.meta({label: "City"})
		.notRequired(),
	loc_lat: Yup.number()
		.meta({label: "Latitude (degrees)"})
		.required('Required')
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.typeError("Must be a number")
		.positive('Must be a positive number'),
	loc_lon: Yup.number()
		.meta({label: "Longitude (degrees)"})
		.required('Required')
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.typeError("Must be a number")
		.negative('West longitudes must be a negative number'),
	loc_elevation: Yup.number()
		.meta({label: "Elevation (feet)"})
		.notRequired()
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.typeError("Must be a number")
		.min(0, 'Must be a positive number'),
	loc_timezone: Yup.string()
		.meta({label: "Time Zone"})
		.default("-5")
		.oneOf(listItems['loc_timezone'].map((item) => item.value), "Not a valid time zone")
		.required('Required'),
	loc_activation_date: Yup.string()
		.meta({label: "Activation date"})
		.notRequired()
		.test("validdate", "Invalid date - must be in the form yyyy-mm-dd", (value) => validatedate(value)),
	station_disconnect_date: Yup.string()
		.meta({label: "Disconnect date", show: {when: 'station_active_status', is: 'disconnected'}})
		.notRequired()
		.test("validdate", "Invalid date - must be in the form yyyy-mm-dd", (value) => validatedate(value)),
	loc_replacement: Yup.boolean()
		.meta({label: "Replacement station"})
		.default(0)
		.notRequired(),
	loc_replacement_newa_id: Yup.string()
		.meta({label: "Existing NEWA id", show: {when: 'loc_replacement', is: 1}})
		.notRequired(),
	station_brand: Yup.string()
		.meta({label: "Station brand"})
		.oneOf(listItems['station_brand'].map((item) => item.value), "Not a valid station brand")
		.required("Required"),
	station_rw_mac: Yup.string()
		.meta({label: "RainWise MAC *", show: {when: 'station_brand', is: 'rainwise'}})
		.when('station_brand', {
			is: (val) => val === 'rainwise',
			then: Yup.string().required("Required"),
			otherwise: Yup.string().notRequired(),
		}),
	station_rw_sn: Yup.string()
		.meta({label: "RainWise serial # *", show: {when: 'station_brand', is: 'rainwise'}})
		.when('station_brand', {
			is: (val) => val === 'rainwise',
			then: Yup.string().required("Required"),
			otherwise: Yup.string().notRequired(),
		}),
	station_rw_id: Yup.string()
		.meta({label: "RainWise.net ID *", show: {when: 'station_brand', is: 'rainwise'}})
		.notRequired(),
	station_rw_model: Yup.string()
		.meta({label: "RainWise model *", show: {when: 'station_brand', is: 'rainwise'}})
		.when('station_brand', {
			is: (val) => val === 'rainwise',
			then: Yup.string().required("Required"),
			otherwise: Yup.string().notRequired(),
		}),
	station_rw_comm: Yup.string()
		.meta({label: "RainWise comm *", show: {when: 'station_brand', is: 'rainwise'}})
		.when('station_brand', {
			is: (val) => val === 'rainwise',
			then: Yup.string().required("Required"),
			otherwise: Yup.string().notRequired(),
		}),
	station_rw_solrad_active: Yup.boolean()
		.meta({label: "SR/LW active", show: {when: 'station_brand', is: 'rainwise'}})
		.default(0)
		.notRequired(),
	station_rw_solrad_cap_off: Yup.boolean()
		.meta({label: "Radiation cap off", show: {when: 'station_brand', is: 'rainwise'}})
		.default(0)
		.notRequired(),
	station_rw_upload_onemin: Yup.boolean()
		.meta({label: "1-minute upload set", show: {when: 'station_brand', is: 'rainwise'}})
		.default(0)
		.notRequired(),
	station_on_sn: Yup.string()
		.meta({label: "Onset serial # *", show: {when: 'station_brand', is: 'onset'}})
		.when('station_brand', {
			is: (val) => val === 'onset',
			then: Yup.string().required("Required"),
			otherwise: Yup.string().notRequired(),
		}),
	sensor_temp_air: Yup.boolean()
		.meta({label: "Air temperature"})
		.default(1)
		.required("Required"),
	sensor_rh: Yup.boolean()
		.meta({label: "Relative humidity"})
		.default(1)
		.required("Required"),
	sensor_rainfall: Yup.boolean()
		.meta({label: "Rainfall"})
		.default(1)
		.required("Required"),
	sensor_solrad: Yup.boolean()
		.meta({label: "Solar radiation"})
		.default(1)
		.required("Required"),
	sensor_solrad_quality: Yup.string()
		.meta({label: "Solar rad quality", show: {when: 'sensor_solrad', is: 1}})
		.default('pending')
		.when('sensor_solrad', {
			is: (val) => val === 1,
			then: Yup.string().oneOf(listItems['sensor_solrad_quality'].map((item) => item.value), "Not a valid quality status")
		})
		.notRequired(),
	sensor_windspd: Yup.boolean()
		.meta({label: "Wind speed"})
		.default(1)
		.required("Required"),
	sensor_windspd_height: Yup.number()
		.meta({label: "Wind speed ht (ft)", show: {when: 'sensor_windspd', is: 1}})
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.typeError("Must be a number")
		.notRequired(),
	sensor_winddir: Yup.boolean()
		.meta({label: "Wind direction"})
		.default(1)
		.required("Required"),
	sensor_wetness: Yup.boolean()
		.meta({label: "Leaf wetness"})
		.default(1)
		.required("Required"),
	sensor_temp_soil: Yup.boolean()
		.meta({label: "Soil temperature"})
		.default(0)
		.required("Required"),
	sensor_moisture_soil: Yup.boolean()
		.meta({label: "Soil moisture"})
		.default(0)
		.required("Required"),
	sensor_temp_soil_detail: Yup.array().default([]).of(Yup.object().shape({
		depth: Yup.string()
			.meta({label: "Depth *", show: {when: 'sensor_temp_soil', is: 1}})
			.when('sensor_temp_soil', {
				is: (val) => val,
				then: Yup.string().required("Required"),
				otherwise: Yup.string().notRequired(),
			}),
		cover: Yup.string()
			.meta({label: "Cover type", show: {when: 'sensor_temp_soil', is: 1}})
			.notRequired(),
		})),
	sensor_moisture_soil_detail: Yup.array().default([]).of(Yup.object().shape({
		depth: Yup.string()
			.meta({label: "Depth *", show: {when: 'sensor_moisture_soil', is: 1}})
			.when('sensor_moisture_soil', {
				is: (val) => val,
				then: Yup.string().required("Required"),
				otherwise: Yup.string().notRequired(),
			}),
		cover: Yup.string()
			.meta({label: "Cover type", show: {when: 'sensor_moisture_soil', is: 1}})
			.notRequired(),
		})),
	owner_name: Yup.string() 
		.meta({label: "Owner name"})
		.default("")
		.required("Required"),
	owner_phone: Yup.string()		 
		.meta({label: "Owner phone"})
		.notRequired()
		.test("validphone", "Format must be ddd-ddd-dddd (with optional extension)", (value) => validatephone(value)),
	owner_email: Yup.string()
		.meta({label: "Owner email"})
		.email("Not a valid e-mail address")
		.notRequired(),
	owner_address: Yup.string()
		.meta({label: "Owner address"})
		.notRequired(),
	owner_city: Yup.string()
		.meta({label: "Owner city"})
		.notRequired(),
	owner_state: Yup.string()
		.meta({label: "Owner state", show: {when: 'owner_country', is: 'us'}})
		.notRequired(),
	owner_province: Yup.string()
		.meta({label: "Owner province", show: {when: 'owner_country', is: 'canada'}})
		.notRequired(),
	owner_zip: Yup.string()
		.meta({label: "Owner zip"})
		.notRequired(),
	owner_country: Yup.string()
		.meta({label: "Owner country"})
		.default("us")
		.notRequired(),
	owner_affiliation: Yup.string()
		.meta({label: "Owner affiliation"})
		.notRequired(),
	owner_affiliation_display: Yup.boolean()
		.meta({label: "Affiliation display"})
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.notRequired(),
	owner_affiliation_url: Yup.string()
		.meta({label: "Affiliation url"})
		.url('Not a valid url')
		.notRequired(),
	owner_affiliation_url_display: Yup.boolean()
		.meta({label: "Affiliation url display"})
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.notRequired(),
	owner_id: Yup.string()
		.meta({label: "Owner ID"})
		.notRequired(),
//	owner_stations: Yup.array()
//		.meta({label: "Owned Stations"})
//		.notRequired(),
	maint1_name: Yup.string()
		.meta({label: "Contact name"})
		.when('station_network', {
			is: (val) => (val === 'newa' || val === 'nwon'),
			then: Yup.string().notRequired(),
			otherwise: Yup.string().notRequired(),
		}),
	maint1_org: Yup.string()
		.meta({label: "Contact organization"})
		.when('station_network', {
			is: (val) => (val === 'newa' || val === 'nwon'),
			then: Yup.string().notRequired(),
			otherwise: Yup.string().notRequired(),
		}),
	maint1_phone: Yup.string()
		.meta({label: "Contact phone"})
		.when('station_network', {
			is: (val) => (val === 'newa' || val === 'nwon'),
			then: Yup.string().notRequired(),
			otherwise: Yup.string().notRequired(),
		})
		.test("validphone", "Format must be ddd-ddd-dddd (with optional extension)", (value) => validatephone(value)),
	maint1_email: Yup.string()
		.meta({label: "Contact e-mail"})
		.email("Not a valid e-mail address")
		.when('station_network', {
			is: (val) => (val === 'newa' || val === 'nwon'),
			then: Yup.string().notRequired(),
			otherwise: Yup.string().notRequired(),
		}),
	maint2_name: Yup.string()
		.meta({label: "Backup name"})
		.notRequired(),
	maint2_phone: Yup.string()
		.meta({label: "Backup phone"})
		.notRequired()
		.test("validphone", "Format must be ddd-ddd-dddd (with optional extension)", (value) => validatephone(value)),
	maint2_email: Yup.string()
		.meta({label: "Backup e-mail"})
		.email("Not a valid e-mail address")
		.notRequired(),
	maint2_affiliation: Yup.string()
		.meta({label: "Backup affiliation"})
		.notRequired(),
	})

export const ownerValidationSchema = Yup.object().shape({
	owner_name: Yup.string() 
		.meta({label: "Owner name *"})
		.required("Required"),
	owner_phone: Yup.string()		 
		.meta({label: "Owner phone"})
		.notRequired()
		.test("validphone", "Format must be ddd-ddd-dddd (with optional extension)", (value) => validatephone(value)),
	owner_email: Yup.string()
		.meta({label: "Owner email"})
		.email("Not a valid e-mail address")
		.notRequired(),
	owner_address: Yup.string()
		.meta({label: "Owner address"})
		.notRequired(),
	owner_city: Yup.string()
		.meta({label: "Owner city"})
		.notRequired(),
	owner_state: Yup.string()
		.meta({label: "Owner state", show: {when: 'owner_country', is: 'us'}})
		.notRequired(),
	owner_province: Yup.string()
		.meta({label: "Owner province", show: {when: 'owner_country', is: 'canada'}})
		.notRequired(),
	owner_zip: Yup.string()
		.meta({label: "Owner zip"})
		.notRequired(),
	owner_country: Yup.string()
		.meta({label: "Owner country"})
		.default("us")
		.notRequired(),
	owner_affiliation: Yup.string()
		.meta({label: "Owner affiliation"})
		.notRequired(),
	owner_affiliation_display: Yup.boolean()
		.meta({label: "Affiliation display"})
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.default(0)
		.notRequired(),
	owner_affiliation_url: Yup.string()
		.meta({label: "Affiliation url"})
		.url('Not a valid url')
		.notRequired(),
	owner_affiliation_url_display: Yup.boolean()
		.meta({label: "Affiliation url display"})
		.default(0)
		.transform((cv, ov) => { return ov === '' ? undefined : cv })
		.notRequired(),
	owner_id: Yup.string()
		.meta({label: "Owner ID"})
		.notRequired(),
	owner_stations: Yup.array()
		.meta({label: "Owned Stations"})
		.default([])
		.notRequired(),
	})
