import { validationSchema } from "./validationSchema"
import { fieldRefs } from "./fieldRefs"

export function defineSchema(results) {
    let valueSchema = {}
    let errorSchema = {}
    Object.entries(fieldRefs).forEach(([ky, rk]) => {
        errorSchema[ky] = ""
        const vfk = validationSchema.fields[ky]
        const [rbase, rsub] = rk.split(".")
        if (!results) {
            valueSchema[ky] = vfk.hasOwnProperty("_default") ? vfk._default : ""
        } else if (results.hasOwnProperty(ky)) {
            valueSchema[ky] = results[ky]
        } else if (vfk._type === 'boolean') {			
            if (rsub) {
                valueSchema[ky] = (results[rbase] && results[rbase].hasOwnProperty(rsub)) ? (results[rbase][rsub] ? 1 : (results[rbase][rsub] === null ? "" : 0)) : ""
            } else {
                valueSchema[ky] = results[rbase] ? 1 : (results[rbase] === null ? "" : 0)
            }
        } else if (Array.isArray(results[rk])) {
            valueSchema[ky] = results[rk]
        } else {
            if (rsub) {
                valueSchema[ky] = (results[rbase] && results[rbase].hasOwnProperty(rsub) && results[rbase][rsub]) ? results[rbase][rsub] : ""
            } else {
                valueSchema[ky] = results[rbase] ? results[rbase] : (vfk._type === 'array' ? [] : "")
            }
        }
        if (valueSchema["brand"] !== "rainwise") {
            valueSchema["station_rw_solrad_active"] = 0
            valueSchema["station_rw_solrad_cap_off"] = 0
            valueSchema["station_rw_upload_onemin"] = 0
        }
    })
    return [valueSchema, errorSchema]
}

export function updateValues(values) {
    let updatedValues = {location:{}, stationRw: {}, stationOn: {}, maint1: {}, maint2: {}}
    Object.entries(fieldRefs).forEach(([ky, rk]) => {
        const vfk = validationSchema.fields[ky]._type
        const [rbase,rsub] = rk.split(".")
        if (rbase === 'owner') {
            // pass - owner changes need to be submitted separately
        } else if (rbase === "sid" && !values[ky]) {
            updatedValues[rbase] = values["station_nid"] + " " + values["station_network"]
        } else if (vfk === 'boolean') {
            if (rsub) {
                updatedValues[rbase][rsub] = values[ky] === 1
            } else {
                updatedValues[rbase] = values[ky] === 1
            }
        } else if (Array.isArray(values[rk])) {
            updatedValues[rk] = values[ky]
        } else {
            if (rsub) {
                updatedValues[rbase][rsub] = vfk === 'number' ? Number(values[ky]) : values[ky]
            } else {
                updatedValues[rbase] = vfk === 'number' ? Number(values[ky]) : values[ky]
            }
        }
    })
    return updatedValues
}