import { useState, useCallback } from "react"

function useForm(valueSchema, errorSchema, validationSchema, callback) {
	const [values, setValues] = useState(valueSchema)
	const [errors, setErrors] = useState(errorSchema)

	// side effects of field changes
	const interaction = (name, value) => {
		if (name === 'station_network') {
			return {station_brand: value === 'newa' ? 'rainwise' : value === 'nwon' ? 'onset' : 'other'}
		} else if (name === 'station_rw_model') {
			return {station_rw_comm: value === 'agrometmkiii' ? 'ip100' : value === 'telemet' ? 'cellular' : value === 'na' ? 'unknown' : ''}
		} else if (name === 'sensor_temp_soil') {
			if (value) {
				return {sensor_temp_soil_detail: [{depth:"", cover:""}]}
			}
		} else if (name === 'sensor_moisture_soil') {
			if (value) {
				return {sensor_moisture_soil_detail: [{depth:"", cover:""}]}
			}
		} else {
			return {}
		}
	}

	// manage value changes and validate changed field
	function handleOnChange(event) {
		let [name, field, arrindx] = event.target.name.split(">")
		const value = event.target.value
		let errMsg = ""
		let values_for_validation ={}
		if (field) {
			let arrall = values[field]
			const arrobj =arrall[arrindx]
			const newarrobj = {...arrobj, [name]: value}
			arrall[arrindx] = newarrobj
			values_for_validation = {...values, [field]: arrall}
			name = field
		} else {
			// Add new value to existing values and add any changes as a result of interactions
			values_for_validation = {...values, ...interaction(name, value), [name]: value}
		}

		// Save to state
		setValues(values_for_validation)

		// Field validation (state might not be updated yet, so use values_for_validation)
		validationSchema.validateAt(name, values_for_validation)
			.catch(function(error) {
				errMsg = error.hasOwnProperty('message') ? error.message : ""
			})
			.finally(function() {
				setErrors({...errors, [name]: errMsg})
			})
	}

	// manage group of value changes (no validation)
	function handleOnChangeGroup(group) {
		// Add new values to existing values
		const values_for_validation = {...values, ...group}
		// Save to state
		setValues(values_for_validation)
	}

	// validate the entire values object
	const validateValues = useCallback(() => {
		let errorList = {}
		try {
			validationSchema.validateSync(values, {abortEarly: false})
			return errorList
		} catch(error) {
			console.log(error)
			error.inner.forEach((ve) => {
				errorList[ve.path] = ve.message
			})
			return errorList
		}
	}, [values, validationSchema])

	// submit if all values validate
	function handleOnSubmit(event) {
		event.preventDefault()
		const vs = validateValues()
		//console.log(vs)
		setErrors({...errors, ...vs})
		if (Object.keys(vs).length === 0) {
			callback()
		}
	}
	
	return { values, errors, handleOnChange, handleOnSubmit, handleOnChangeGroup }
}

export default useForm
