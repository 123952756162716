import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
	gridColumn: {
		border: "1px solid lightgray",
		backgroundColor: "#f4fef4",
		margin: "0.25em",
	},
	gridColumnHead: {
		fontSize: "1.1em",
		textAlign: "center",
		color: "darkblue"
	},
	input: {
		width: "12em",
	},
	dateInput: {
		width: "12em",
		marginTop: "0.5em",
		marginBottom: "0.25em",
	},
	largeInput: {
		width: "24em"
	},
	smallInput: {
		width: "10em",
		marginLeft: "1em",
	},
	smallDateInput: {
		width: "10em",
		marginTop: "0.5em",
		marginBottom: "0.25em",
		marginLeft: "1em",
	},
	infomsg: {
		marginLeft: "1em",
	},
	submit: {
		margin: "0.5em",
		border: "1px solid lightgray",
		backgroundColor: "#f4fef4",
		color: "darkblue"
	},
	verificationBox: {
		border: "1pt solid green", 
		padding:"0.5em", 
		background:"palegoldenrod", 
		fontSize:"0.8em"
	},
	selectionsBox: {
		border:"1pt solid gray", 
		padding:"0.5em 0.5em 0", 
		display:"inline-block", 
		margin:"1em", 
		textAlign:"center"
	},
	fileViewAll: {
		border: "1pt solid gray", 
		padding: "0px 0px 0px 4px", 
		color: "black"
	},
	fileViewErr: {
		border: "1pt solid gray", 
		padding: "0px 0px 0px 4px", 
		color: "red"
	},
	fileViewButton: {
		marginLeft:"1em", 
		marginTop:"0.5em", 
		marginBottom:"0.5em", 
		padding:"2px 6px"
	},
})
