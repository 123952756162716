import React, { Fragment, useState, useEffect, useContext } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { listItems } from './validationSchema';
import { OneStationQuery } from './QueryStation';
import { client } from './client';
import { STATIONS_BY_STATE, STATIONS_BY_STATE_STATUS } from './queries';
import { StylesContext } from './App';

const AllStationsQuery = (props) => {
  const { srchState, srchStatus } = props;
  const [ selectedStation, setSelectedStation ] = useState("");
  const [ allStationsData, setAllStationsData ] = useState({})
  const classes = useContext(StylesContext);

  const stationsByState = async(srchState, srchStatus) => {
    // setup and execute the query
    try {
        const q = srchStatus == "" ? STATIONS_BY_STATE(srchState.toLowerCase()) : STATIONS_BY_STATE_STATUS(srchState.toLowerCase(), srchStatus);
        const data = await client.query(q);
        setAllStationsData(data.data.data)
    } catch (error) {
        setAllStationsData({});
        console.log('Database station by state read error: '+ error);
    }
  };

  useEffect(() => {
    if (srchState.length > 0) {
        setAllStationsData({});
        stationsByState(srchState, srchStatus);
    }
}, [srchState, srchStatus])

  return (
      <>
        {allStationsData.length > 0 && !selectedStation && 
            <div className={classes.selectionsBox}>
                <b>Matches</b> (click to select):
                <List dense={true} disablePadding={true}>
                    {allStationsData.map((so) => (
                        <Fragment key={so.sid}>
                            <ListItem key={so.sid} button onClick={() => {setSelectedStation(so.sid)}}>
                                <ListItemText 
                                    style={{margin:"0"}} 
                                    primary={so.name}
                                    secondary={so.location ? [so.sid,so.location.lat,so.location.lon].join("  ") : so.sid+"  -  -"}
                                 />  
                            </ListItem>
                        </Fragment>
                    ))}
                </List>
            </div>
        }
        {allStationsData.length === 0 && 
            <p className={classes.infomsg}>
                Cannot find stations in selected state - {srchState}
            </p>
        }
        {selectedStation &&
            <OneStationQuery
                srchStation={selectedStation}
            />
        }
      </>
  );
}

export default function QueryAllStations(props) {
    const { viewStations, setViewStations } = props;
    const classes = useContext(StylesContext)
    const [ searchState, setSearchState ] = useState("");
    const [ searchStatus, setSearchStatus ] = useState("");
     
    // allow submit using enter key, instead of requiring button click
    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            setViewStations(true); 
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
    }, [setViewStations]);

    // new search
    useEffect(() => {
        if (!viewStations) {
            setSearchState("");
            setSearchStatus("");
        }
    }, [viewStations]);
    
    return (
        <>
            {!viewStations &&
                <div className={classes.selectionsBox}>
                    <TextField
                        name="runquery"
                        label="State abbreviation"
                        placeholder="Search state"
                        value={searchState}
                        onChange={(e) => setSearchState(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        autoFocus={true}
                        autoComplete="off"
                        style={{marginRight:"1em"}}
                    />
                    <TextField
                        name="runquery"
                        label="Status (optional)"
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        className={classes.input}
                        select={true}
                        autoComplete="off"
                    >
                        {listItems.station_active_status.map(option => (
                            <MenuItem key={option.value} value={option.value} selected={searchStatus === option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <br />
                    <Button 
                        variant="contained" 
                        size="small" 
                        className={classes.submit} 
                        onClick={() => {setViewStations(true);}}
                    >
                        Run search
                    </Button>
                </div>
            }       
            {viewStations && searchState !== "" &&
                <AllStationsQuery
                    srchState={searchState}
                    srchStatus={searchStatus}
                />
            }
        </>
    )
}