import React, { useState, useEffect, useContext } from "react"
import { validationSchema } from './validationSchema'
import Typography from '@mui/material/Typography'
import Button from "@mui/material/Button"
import ViewStation from './ViewStation'
import { updateValues } from './ViewRoutines'
import { StylesContext } from './App'
//import { OWNER_BY_NAME, ADD_STATION } from './queries'

export default function FileDisplay(props) {
	const { data, justShowErrors } = props
	const classes = useContext(StylesContext)
	const [ errs, setErrs ] = useState({})
	const [ fullView, setFullView ] = useState(false)
	const [ ownerInfo, setOwnerInfo ] = useState({})
	const [ errorMsgs, setErrorMsgs ] = useState("")
//	const [ queryOwner, { loading: ownerLoading, error: ownerError, data: ownerData } ] = useLazyQuery(OWNER_BY_NAME);
//	const [ addStation, { data: addedStationData } ] = useMutation(ADD_STATION, {
//		refetchQueries: ["OwnerByName"],
//		onError(oError) { alert('Station creation error - ' + oError.graphQLErrors[0].message) }
//	})
	queryOwner = null;
	addStation = null;

	// when data loads
	useEffect(() => {
		if (data) {
			let errorList = {}
			try {
				validationSchema.validateSync(data, {abortEarly: false})
			} catch(error) {
				error.inner.forEach((ve) => {
					errorList[ve.path] = ve.message
				})
			} finally {
				setErrs(errorList)
			}
			if (data.owner_name && data.owner_name.length > 0) {
				queryOwner({variables: {name: data.owner_name}})
				setOwnerInfo({
					name: data.owner_name, 
					phone: data.owner_phone, 
					email: data.owner_email, 
					address: data.owner_address, 
					city: data.owner_city, 
					country: data.owner_country,
					state: data.owner_state,
					province: data.owner_province, 
					zip: data.owner_zip, 
					affiliation: data.owner_affiliation, 
					affiliationDisplay: data.owner_affiliation_display === 1,
					affiliationUrl: data.owner_affiliation_url, 
					affiliationUrlDisplay: data.owner_affiliation_url_display === 1,
				})
			} else {
				setErrorMsgs('No owner name assigned for ' + data.station_sid + '; skipping any other owner information.')
			}
		}
	}, [data, queryOwner])

	// handle queryOwner results
	useEffect(() => {
		if (ownerData) {
			if (ownerData.ownerByName) {
				setOwnerInfo({})
				data.owner_id = ownerData.ownerByName._id
				data.owner_name = ownerData.ownerByName.name
				data.owner_phone = ownerData.ownerByName.phone
				data.owner_email = ownerData.ownerByName.email
				data.owner_address = ownerData.ownerByName.address
				data.owner_city = ownerData.ownerByName.city
				data.owner_country = ownerData.ownerByName.country
				data.owner_state = ownerData.ownerByName.state
				data.owner_province = ownerData.ownerByName.province
				data.owner_zip = ownerData.ownerByName.zip
				data.owner_affiliation = ownerData.ownerByName.affiliation
				data.owner_affiliation_display = ownerData.ownerByName.affiliationDisplay ? 1 : 0
				data.owner_affiliation_url = ownerData.ownerByName.affiliationUrl
				data.owner_affiliation_url_display = ownerData.ownerByName.affiliationUrlDisplay ? 1 : 0
			}
		}
	}, [ownerData, data])

	function handleViewStation() {
		setFullView(true)
	}

	function handleExitViewStation() {
		setFullView(false)
	}

	// add station to database, including owner handling
	function handleAddStation() {
		const updatedValues = updateValues(data)
		const ownerObject = data.owner_id ? { connect: data.owner_id } : (Object.keys(ownerInfo).length ? { create: ownerInfo } : null)
		if (ownerObject) {
			addStation({ variables: { data: {...updatedValues, owner: ownerObject} }})
		} else {
			addStation({ variables: { data: {...updatedValues} }})
		}
	}

	return (
		<>
			{fullView &&
				<>
					<Typography variant="subtitle1">
						{data.station_sid} - {data.station_name} 
						{!addedStationData &&
							<Button variant="outlined" size="small" className={classes.fileViewButton} onClick={handleExitViewStation}>
								Exit full view
							</Button>
						}
					</Typography>
					<ViewStation 
						results={data}
					/>	
				</>	
			}
			{!fullView &&
				<>
					<Typography variant="subtitle1">
						{data.station_sid} - {data.station_name} 
						{!addedStationData &&
							<>
								- Owner: {ownerLoading}{ownerError}{data.owner_id ? data.owner_id : "not connected"}
								<Button variant="outlined" size="small" className={classes.fileViewButton} onClick={handleViewStation}>
									Full view
								</Button>
								<Button variant="outlined" size="small" className={classes.fileViewButton} onClick={handleAddStation}>
									Add station
								</Button>
							</>
						}
						{errorMsgs &&
							<span className={classes.fileViewErr}> {errorMsgs}</span>
						}
					</Typography>
					{!addedStationData && !justShowErrors &&
						Object.keys(data).map((dky) => (
							<span className={errs.hasOwnProperty(dky)?classes.fileViewErr:classes.fileViewAll} key={dky}>{dky}: 
							  {typeof data[dky] !== "object" ? data[dky] : JSON.stringify(data[dky])} {errs.hasOwnProperty(dky) ? errs[dky] : ""}</span>
						))
					}
					{!addedStationData && justShowErrors &&
						Object.keys(errs).map((eky) => (
							<span className={classes.fileViewErr} key={eky}> {eky}: 
							  {typeof data[eky] !== "object" ? data[eky] : JSON.stringify(data[eky])} {errs[eky]}</span>
						))
					}
					{addedStationData &&
						<div className={classes.verificationBox}>
							<span>Submitted data: {JSON.stringify(addedStationData.createStation,null,2)}</span>
						</div>
					}
				</>
			}
		</>
	)
}
