import { fql } from "fauna";

export function ALL_STATIONS() {
  return fql`Station.all().paginate(2000)`;
};

export function STATION_BY_SID(sid) {
  return fql`Station.stationById(${sid}, {from:"A", to:"z"})`;
};

export function STATIONS_BY_STATE(srchState) {
  return fql`(Station.all().where(.state == ${srchState}) {id,sid,name,location}).paginate(1000)`;
};

export function STATIONS_BY_STATE_STATUS(srchState, srchStatus) {
  return fql`(Station.stationsByState(${srchState}, {from:${srchStatus}, to:${srchStatus}}) {id,sid,name,location}).paginate(1000)`;
};

export function STATIONS_BY_NAME(srchName) {
  return fql`(Station.all().where(.name.toLowerCase().includes(${srchName}.toLowerCase())) {id,sid,name}).paginate(100)`;
};

export function CREATE_STATION(data) {
  return fql`Station.create(${data})`;
};

export function DELETE_STATION(id) {
  return fql`Station.byId(${id})!.delete()`;
};

export function UPDATE_STATION(id, data) {
  return fql`Station.byId(${id})!.update(${data})`;
};

export function OWNER_BY_ID(id) {
  return fql`Owner.byId(${id})`;
};

export function OWNERS_BY_NAME(srchName) {
  return fql`(Owner.all().where(.name.toLowerCase().includes(${srchName}.toLowerCase())) {id,affiliation,name}).paginate(100)`;
};

export function OWNER_BY_EXACT_NAME(name) {
  return fql`Owner.ownerByName(${name})`;
};

export function CREATE_OWNER(data) {
  return fql`Owner.create(${data})`;
};

export function DELETE_OWNER(id) {
  return fql`Owner.byId(${id})!.delete()`;
};

export function UPDATE_OWNER(id, data) {
  return fql`Owner.byId(${id})!.update(${data})`;
};


export function UPDATE_OWNER_INFO() {
  return ;
};

/*
export const ALL_STATIONS = gql`    
  query AllStations {
    allStations(_size:2000) {
      data {
        name
        sid
      }
    }
  }
`;

export const STATION_BY_SID = gql`    
  query StationBySid($sid: String!) {
    stationBySid(sid: $sid) {
      _id
      sid
      nid
      network
      name
      state
	    activeStatus
	    brand
      location {
        country
        province
        county
        city
        lat
        lon
        elevation
        timezone
      }
      notes {
          noteText
          entryDate
          entryCreator
      }
      activationDate
      disconnectDate
      replacement
      replacementNewaId
      stationRw {
        sn
        mac
        id
        model
        comm
        solradActive
        solradCapOff
        uploadOnemin
      }
      stationOn {
        sn
      }
      sensorTempAir
      sensorRh
      sensorRainfall
      sensorSolrad
      sensorWindspd
      sensorWinddir
      sensorWetness
      sensorTempSoil
      sensorMoistureSoil
      sensorWindspdHeight
      sensorSolradQuality
      sensorTempSoilDetail {
        depth
        cover
      }
      sensorMoistureSoilDetail {
        depth
        cover
      }
      owner {
        name
        affiliation
        email
        phone
        address
        city
        country
        state
        province
        zip
        affiliationUrl
        affiliationDisplay
        affiliationUrlDisplay
        _id
      }
      maint1 {
        name
        affiliation
        email
        phone
      }
      maint2 {
        name
        affiliation
        email
        phone
      }
    }
  }
`;


export const ADD_STATION = gql`
  mutation AddStation($data: StationInput!) {
    createStation( data: $data) {
      sid
      nid
      network
      name
      state
	    activeStatus
	    brand
      location {
        country
        province
        county
        city
        lat
        lon
        elevation
        timezone
      }
      notes {
        noteText
        entryDate
        entryCreator
      }
      activationDate
      disconnectDate
      replacement
      replacementNewaId
      stationRw {
        sn
        mac
        id
        model
        comm
        solradActive
        solradCapOff
        uploadOnemin
      }
      stationOn {
        sn
      }
      sensorTempAir
      sensorRh
      sensorRainfall
      sensorSolrad
      sensorWindspd
      sensorWinddir
      sensorWetness
      sensorTempSoil
      sensorMoistureSoil
      sensorWindspdHeight
      sensorSolradQuality
      sensorTempSoilDetail {
        depth
        cover
      }
      sensorMoistureSoilDetail {
        depth
        cover
      }
      maint1 {
        name
        affiliation
        email
        phone
      }
      maint2 {
        name
        affiliation
        email
        phone
      }
      owner {
        name
        affiliation
        email
        phone
        address
        city
        country
        state
        province
        zip
        affiliationUrl
        affiliationDisplay
        affiliationUrlDisplay
        _id
      }
    }
  }
`;

export const UPDATE_STATION = gql`    
  mutation UpdateStation($id: ID!, $data: StationInput!) {
    updateStation( id: $id, data: $data) {
      sid
      nid
      network
      name
      state
	    activeStatus
	    brand
      location {
        country
        province
        county
        city
        lat
        lon
        elevation
        timezone
      }
      notes {
          noteText
          entryDate
          entryCreator
      }
      activationDate
      disconnectDate
      replacement
      replacementNewaId
      stationRw {
        sn
        mac
        id
        model
        comm
        solradActive
        solradCapOff
        uploadOnemin
      }
      stationOn {
        sn
      }
      sensorTempAir
      sensorRh
      sensorRainfall
      sensorSolrad
      sensorWindspd
      sensorWinddir
      sensorWetness
      sensorTempSoil
      sensorMoistureSoil
      sensorWindspdHeight
      sensorSolradQuality
      sensorTempSoilDetail {
        depth
        cover
      }
      sensorMoistureSoilDetail {
        depth
        cover
      }
      maint1 {
        name
        affiliation
        email
        phone
      }
      maint2 {
        name
        affiliation
        email
        phone
      }
    }
  }
`;
// Owner info removed from above return since it is not in update sent to database //

export const UPDATE_OWNER_INFO = gql`    
  mutation UpdateStation($id: ID!, $data: StationInput!) {
    updateStation( id: $id, data: $data) {
      sid
      owner {
        name
        affiliation
        email
        phone
        address
        city
        country
        state
        province
        zip
        affiliationUrl
        affiliationDisplay
        affiliationUrlDisplay
        _id
      } 
    }
  }
`;
// Just owner info returned from this station update (owner connect/disconnect/create) //

export const DELETE_STATION = gql`    
  mutation DeleteStation($id: ID!) {
    deleteStation( id: $id ) {
      sid
      name
    }
  }
`;


export const ALL_OWNERS = gql`    
  query AllOwners {
    allOwners(_size:1000) {
      data {
        name
        affiliation
      }
    }
  }
`;

export const OWNER_BY_NAME = gql`    
  query OwnerByName($name: String!) {
    ownerByName(name: $name) {
      name
      affiliation
      email
      phone
      address
      city
      country
      state
      province
      zip
      affiliationUrl
      affiliationDisplay
      affiliationUrlDisplay
      _id
      stations(_size: 1000) {
        data {
          name
          sid
        }
      }
    }
  }
`;

export const ADD_OWNER = gql`
  mutation AddOwner($data: OwnerInput!) {
    createOwner( data: $data ) {
      name
      affiliation
      email
      phone
      address
      city
      country
      state
      province
      zip
      affiliationUrl
      affiliationDisplay
      affiliationUrlDisplay
      _id
      stations {
        data {
          name
          sid
        }
      }
    }
  }
`;

export const UPDATE_OWNER = gql`    
  mutation UpdateOwner($id: ID!, $data: OwnerInput!) {
    updateOwner( id: $id, data: $data) {
      name
      affiliation
      email
      phone
      address
      city
      country
      state
      province
      zip
      affiliationUrl
      affiliationDisplay
      affiliationUrlDisplay
      _id
    }
  }
`;

export const DELETE_OWNER = gql`    
  mutation DeleteOwner($id: ID!) {
    deleteOwner( id: $id ) {
      name
    }
  }
`;

export const STATIONS_IN_STATE = gql`
query StationInState($state: String!) {
  stationsInState(state: $state, _size: 2000) {
      data {
        sid
        name
        location {
          lat
          lon
        }
      }
    }
  }
`;

export const STATIONS_IN_STATE_ACTIVE = gql`
query StationInState($state: String!, $activeStatus: String!) {
  stationsInStateActive(state: $state, activeStatus: $activeStatus, _size: 2000) {
      data {
        sid
        name
        location {
          lat
          lon
        }
      }
    }
  }
`;

*/