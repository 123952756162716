import React, { useState, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { StylesContext } from "./App"
import { validationSchema } from './validationSchema'

export default function RenderDateField(props) {
    const { field, ovalue, error, handleOnChange, useClassName=null, values={}} = props
    const classes = useContext(StylesContext)
    const [ date, setDate ] = useState(ovalue === "" ? null : dayjs(ovalue))
    const [ ffield, arrayField ] = field.split(">")
    const vs = !arrayField ? validationSchema.fields[ffield] : validationSchema.fields[arrayField]._subType.fields[ffield]

    const handleDateChange = (dateValue) => {
		if (dateValue) {
			setDate(dateValue)
			const dateString = dateValue.toISOString().slice(0,10)
			handleOnChange({target: {name: field, value: dateString}})
		}
	}

    return (
        <>
            {(!vs._meta.show || values[vs._meta.show.when] === vs._meta.show.is) &&
                <>
                    <DatePicker 
                        value={date}
                        onChange={handleDateChange}
                        format="YYYY-MM-DD"
                        label={vs._meta.label}
                        minDate={dayjs('1990-1-1')}
                        maxDate={dayjs()}
                        className={useClassName ? classes[useClassName] : classes.dateInput}
                    />
                    <br />
                </>
            }
        </>
    )
}

/* OLD before last <br>

<TextField
                        id={field}
                        name={field}
                        value={ovalue}
                        label={vs._meta.label}
                        required={vs._exclusive.hasOwnProperty('required') && vs._exclusive.required}
                        error={error.length > 0}
                        helperText={error}
                        margin="dense"
                        variant="outlined"
                        onChange={handleOnChange}
                        className={classes[useClassName]}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={handleCalendarIconClick}
                                    >
                                        <CalendarToday />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {showDatepicker &&
                        <>
                            <br />
                            <RenderDatepicker
                                handleOnChange={handleOnChange}
                                closeDatepicker={() => setShowDatepicker(false)}
                                initialDate={ovalue}
                                minDate={new Date(1948,0,1)}
                                maxDate={today}
                                field={field}
                            />
                        </>
                    }
                    */