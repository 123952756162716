export const fieldRefs = { 
        "station_sid" : "sid",
        "station_nid" : "nid",
        "station_network" : "network", 
        "station_name" : "name", 
        "station_state" : "state", 
        "station_active_status" : "activeStatus", 
        "station_disconnect_date" : "disconnectDate",
        "notes" : "notes",
        "loc_country" : "location.country", 
        "loc_county" : "location.county", 
        "loc_province" : "location.province", 
        "loc_city" : "location.city", 
        "loc_lat" : "location.lat", 
        "loc_lon" : "location.lon", 
        "loc_elevation" : "location.elevation", 
        "loc_timezone" : "location.timezone",
        "loc_activation_date" : "activationDate", 
        "loc_replacement" : "replacement", 
        "loc_replacement_newa_id" : "replacementNewaId",
        "station_brand" : "brand", 
        "station_rw_sn" : "stationRw.sn", 
        "station_rw_id" : "stationRw.id", 
        "station_rw_model" : "stationRw.model", 
        "station_rw_comm" : "stationRw.comm", 
        "station_rw_mac" : "stationRw.mac", 
        "station_rw_solrad_active" : "stationRw.solradActive", 
        "station_rw_solrad_cap_off" : "stationRw.solradCapOff", 
        "station_rw_upload_onemin" : "stationRw.uploadOnemin",
        "station_on_sn" : "stationOn.sn",
        "sensor_temp_air" : "sensorTempAir", 
        "sensor_rh" : "sensorRh", 
        "sensor_rainfall" : "sensorRainfall", 
        "sensor_wetness" : "sensorWetness", 
        "sensor_solrad" : "sensorSolrad", 
        "sensor_solrad_quality" : "sensorSolradQuality",
        "sensor_windspd" : "sensorWindspd", 
        "sensor_windspd_height" : "sensorWindspdHeight", 
        "sensor_winddir" : "sensorWinddir", 
        "sensor_temp_soil" : "sensorTempSoil", 
        "sensor_temp_soil_detail" : "sensorTempSoilDetail", 
        "sensor_moisture_soil" : "sensorMoistureSoil", 
        "sensor_moisture_soil_detail" : "sensorMoistureSoilDetail", 
        "owner_name" : "owner.name", 
        "owner_phone" : "owner.phone", 
        "owner_email" : "owner.email", 
        "owner_address" : "owner.address", 
        "owner_city" : "owner.city", 
        "owner_country" : "owner.country", 
        "owner_state" : "owner.state",
        "owner_province" : "owner.province", 
        "owner_zip" : "owner.zip", 
        "owner_affiliation" : "owner.affiliation", 
        "owner_affiliation_display" : "owner.affiliationDisplay", 
        "owner_affiliation_url" : "owner.affiliationUrl", 
        "owner_affiliation_url_display" : "owner.affiliationUrlDisplay",
        "owner_id" : "owner.id",
        "maint1_name" : "maint1.name", 
        "maint1_org" : "maint1.affiliation", 
        "maint1_phone" : "maint1.phone", 
        "maint1_email" : "maint1.email", 
        "maint2_name" : "maint2.name", 
        "maint2_affiliation" : "maint2.affiliation", 
        "maint2_phone" : "maint2.phone", 
        "maint2_email" : "maint2.email"
    }