import React, { useState, useEffect } from "react"
import { validationSchema } from './validationSchema'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FileDisplay from './FileDisplay'

export default function ReadFile(props) {
	const [data, setData] = useState(null)
	const [justShowErrors, setJustShowErrors] = useState(false)
	const fields = validationSchema._nodes

	// defaults for required values probably not in imported file
	let init = {
		loc_country: 'us',
		owner_country: 'us',
		loc_replacement: 0,
		loc_timezone: "na",
		station_rw_id: "unknown",
		station_rw_model: "na",
		station_rw_solrad_active: 0,
		station_rw_solrad_cap_off: 0,
		station_rw_upload_onemin: 0,
		sensor_solrad_quality: "na",
		station_brand: "other",
	}
	// initialize all other to empty
	fields.forEach(ky => {
		if (!init.hasOwnProperty(ky)) {
			init[ky] = ""
		}
	})

	useEffect(() => {
		setData(null)
		setJustShowErrors(false)
	}, [props.di])
	
	const displayData = (content) => {
		var staData = {}
		for (var sid in content) {
 			if (content.hasOwnProperty(sid)) {
				staData[sid] = {...init, ...content[sid]}
			}
		}
		setData(staData)
	}

	const handleFileSelect = (evt) => {
		setData(null)
		let files = evt.target.files
		if (!files.length) {
			alert('No file selected')
			return
		}
		let file = files[0]
		let reader = new FileReader()
		reader.onload = (e) => {
			const json = JSON.parse(e.target.result)
			displayData(json);
		}
		reader.readAsText(file)
	}

	const handleCheckboxChange = (evt) => {
		setJustShowErrors(evt.target.checked)
	}

	return (
		<div style={{paddingLeft:"1em"}}>
			{!data &&
				<TextField
					type="file"
					margin="dense"
					variant="outlined"
					onChange={handleFileSelect}
				/>
			}
			{data &&
				<>
					<FormControlLabel
						control={
							<Checkbox 
								checked={justShowErrors} 
								onChange={handleCheckboxChange} 
								value={justShowErrors} 
							/>
						}
						label="Just show errors"
					/>
					{Object.keys(data).map((sky) => (
						<FileDisplay 
							key={sky}
							data={data[sky]}
							justShowErrors={justShowErrors}
						/>
					))}
				</>
			}
		</div>
	)
}