import React, { Fragment, useState, useEffect, useContext } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ViewStation from './ViewStation';
import { StylesContext } from './App';
import { client } from './client';
import { STATION_BY_SID, STATIONS_BY_NAME, OWNER_BY_ID } from './queries';

// Get data for single station by sid
export const OneStationQuery = (props) => {
    const { srchStation } = props;
    const classes = useContext(StylesContext);
    const [ stationMeta, setStationMeta ] = useState({});
    const [ loading, setLoading ] = useState(false)
  
    const stationById = async(sid) => {
        // setup and execute the query
        try {
            setLoading(true)
            const q = STATION_BY_SID(sid);
            const data = await client.query(q);
            if (data.data.data.length == 0) { throw "No station with sid " + sid }
            if (data.data.data[0].owner.hasOwnProperty("id")) {
                const owner_id = data.data.data[0].owner.id;
                const oq = OWNER_BY_ID(owner_id);
                const owner_data = await client.query(oq);
                data.data.data[0].owner = owner_data.data;
            } else {
                data.data.data[0].owner = {};
            }
            setStationMeta(data.data.data[0]);
            setLoading(false);
        } catch (error) {
            setStationMeta({});
            console.log('Database read error: '+ error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (srchStation.length) {
            setStationMeta({});
            stationById(srchStation);
        }
    }, [srchStation])

    return (
      <>
        {Object.keys(stationMeta).length > 0 &&
            <ViewStation 
                results={stationMeta}
            />
        }
        {Object.keys(stationMeta).length == 0 && !loading && 
            <p className={classes.infomsg}>
                No station associated with sid of "{srchStation}"
            </p>
        }
       </>
    );
}
  
// Get all stations and display those whose station name contains the search string (srchName)
const AllStationQuery = (props) => {
    const { srchName } = props;
    const classes = useContext(StylesContext);
    const [ selectedSid, setSelectedSid ] = useState("");
    const [ allStationsData, setAllStationsData ] = useState([]);
    const [ loading, setLoading ] = useState({});

    const stationsByName = async(srchName) => {
        // setup and execute the query
        try {
            setLoading(true)
            const q = STATIONS_BY_NAME(srchName);
            const data = await client.query(q);
            setAllStationsData(data.data.data)
            setLoading(false)
        } catch (error) {
            setAllStationsData({});
            console.log('Database station by name read error: '+ error);
            setLoading(false)
        }
    };

    useEffect(() => {
        if (srchName.length) {
            setAllStationsData([]);
            stationsByName(srchName);
        };
    }, [srchName]);

    return (
      <>
        {allStationsData.length > 0 && !selectedSid &&
            <div className={classes.selectionsBox}>
                <b>Matches</b> (click selection):
                <List dense={true} disablePadding={true}>
                    {allStationsData.map((so) => (
                        <Fragment key={so.sid}>
                            <ListItem key={so.sid} button onClick={() => {setSelectedSid(so.sid)}}>
                                <ListItemText style={{margin:"0"}} primary={so.name} secondary={so.sid} />  
                            </ListItem>
                        </Fragment>
                    ))}
                </List>
            </div>
        }
        {allStationsData.length == 0 && !loading && 
            <p className={classes.infomsg}>
                Cannot find any matching stations
            </p>
        }
        {selectedSid.length > 0 && 
            <OneStationQuery
                srchStation={selectedSid}
            />
        }
      </>
  );
}

export default function QueryStation(props) {
    const classes = useContext(StylesContext);
    const { viewStation, setViewStation } = props;
    const [ searchStation, setSearchStation ] = useState("");
    const [ searchName, setSearchName ] = useState("");
 
    // allow submit using enter key, instead of requiring button click
    useEffect(() => {
        const listener = event => {
          if ((event.code === "Enter" || event.code === "NumpadEnter") /*&& event.target.name === "runquery"*/) {
            setViewStation(true); 
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
    }, [setViewStation]);

    // new search
    useEffect(() => {
        if (!viewStation) {
            setSearchStation("");
            setSearchName("");
        }
    }, [viewStation]);
    
    return (
        <>
            {!viewStation &&
                <div className={classes.selectionsBox}>
                    <TextField
                        name="runquery"
                        label="Search Station ID"
                        value={searchStation}
                        onChange={(e) => setSearchStation(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                        autoFocus={true}
                    />
                    <Typography style={{margin:"0 0.5em 0", lineHeight:"4em"}} display="inline">
                        - or -
                    </Typography>
                    <TextField
                        name="runquery"
                        label="Search Station Name"
                        placeholder="Name contains ..."
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                    />
                    <br />
                    <Button 
                        variant="contained" 
                        size="small" 
                        className={classes.submit} 
                        onClick={() => {setViewStation(true)}}
                    >
                        Start search
                    </Button>
                </div>
            }         
            {viewStation && searchStation !== "" &&
                <OneStationQuery
                    srchStation={searchStation}
                />
            }
            {viewStation && searchName !== "" &&
                <AllStationQuery
                    srchName={searchName}
                />
            }
        </>
    )
}

/* 

stand alone:

    const ownerById = async(owner_id) => {
        // setup and execute the query
        try {
            const q = OWNER_BY_ID(owner_id);
            const owner_data = await client.query(q)
            return owner_data.data
        } catch (error) {
            console.log('Database owner read error: '+ error);
            return {}
        }        
    }

    */
