import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useForm from "./useForm";
import RenderTextField from "./RenderTextField";
import DeleteAlertDialog from "./DeleteAlertDialog";
import { ownerValidationSchema } from "./validationSchema";
import { StylesContext } from "./App";
import { fieldRefs } from "./fieldRefs";
import { client } from "./client";
import { UPDATE_OWNER, CREATE_OWNER, DELETE_OWNER } from "./queries";

export default function ViewOwner(props) {
	const {results=null} = props;
	const classes = useContext(StylesContext);
	const [ addedData, setAddedData ] = useState({});
	const [ updatedData, setUpdatedData ] = useState({});
	const [ showDeleteAlert, setShowDeleteAlert ] = useState(false);
	const [ showForm, setShowForm ] = useState(true);
	const [ jsonResults, setJsonResults ] = useState("");

	const ownerFields = ["owner_name", "owner_phone", "owner_email", "owner_address", "owner_city", "owner_country", 
		"owner_state", "owner_province", "owner_zip", "owner_affiliation", "owner_affiliation_display", 
		"owner_affiliation_url", "owner_affiliation_url_display"]             

	const addOwner = async(adddata) => {
        // setup and execute the query
        try {
            const q = CREATE_OWNER(adddata);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
            setAddedData(data.data);
        } catch (error) {
            console.log('Database owner create error: ' + error.code);
            setAddedData(error.code);
        }
    };

	const deleteOwner = async(id) => {
        // setup and execute the query
        try {
            const q = DELETE_OWNER(id);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
			setJsonResults('Owner deleted');
        } catch (error) {
            console.log('Database owner delete error: '+ error);
			setJsonResults('Database owner delete error');
        }
    };

	const updateOwner = async(params) => {
		const { id, updata } = params;
        // setup and execute the query
        try {
            const q = UPDATE_OWNER(id, updata);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
            setUpdatedData(data.data);
        } catch (error) {
            console.log('Database owner update error: ' + error.code);
            setUpdatedData(error.code);
        }
    };

	// Define value and error schema
	const document_id = results ? results.id : ""
	let valueSchema = {}
	let errorSchema = {}
    const iter = [...ownerFields, "owner_stations"]
	iter.forEach((ky) => {
		errorSchema[ky] = ""
		const vfk = ownerValidationSchema.fields[ky]
        const rk = fieldRefs[ky] || ky.replace("_", ".")
		const rbase = rk.split(".")[1]
		if (!results) {
			valueSchema[ky] = vfk.hasOwnProperty("_default") ? vfk._default : ""
		} else if (vfk._type === 'boolean') {			
            valueSchema[ky] = results[rbase] ? 1 : (results[rbase] === null ? "" : 0)
		} else if (vfk._type === 'array') {		
            valueSchema[ky] = results[rbase] ? results[rbase].data : []
		} else {
			valueSchema[ky] = results[rbase] ? results[rbase] : ""
		}
	})

	const { values, errors, handleOnChange, handleOnSubmit } = useForm(
		valueSchema,
		errorSchema,
		ownerValidationSchema,
		onSubmitForm
	)

	useEffect(() => {
		if (Object.keys(updatedData).length > 0) {
			setJsonResults("Submitted data: " + JSON.stringify(updatedData, null, 2))
		}
	}, [updatedData])

	useEffect(() => {
		if (Object.keys(addedData).length > 0) {
			setJsonResults("Submitted data: " + JSON.stringify(addedData, null, 2))
		}
	}, [addedData])

	// add or update owner
	function onSubmitForm() {
		let updatedValues = {}
		ownerFields.forEach((ky) => {
			const vfk = ownerValidationSchema.fields[ky]._type
            const rk = fieldRefs[ky] || ky.replace("_", ".")
			const rbase = rk.split(".")[1]
			if (vfk === 'boolean') {			
				updatedValues[rbase] = values[ky] === 1
			} else {
				updatedValues[rbase] = vfk === 'number' ? Number(values[ky]) : values[ky]
			}
		})
 		if (document_id) {
			updateOwner({id: document_id, updata: {...updatedValues}})
		} else {
			addOwner(updatedValues);
		}
	}

	// delete station (confirm first)
	function handleDeleteOwner() {
		setShowDeleteAlert(true);
	}

	// delete confirmed
	function handleDeleteConfirm() {
		deleteOwner(document_id);
		setShowDeleteAlert(false);
		setShowForm(false);
	}

	return (
		<div style={{padding:"1em"}}>
			{showForm &&
				<form onSubmit={handleOnSubmit} noValidate>
					<Grid
						container
						direction="row"
						alignContent="center"
						justify="flex-start"
						alignItems="flex-start"
						spacing={1}
					>
						<Grid item className={classes.gridColumn}>
							<h1 className={classes.gridColumnHead}>Owner Information</h1>
							{ownerFields.map(ky => (
								<RenderTextField 
									key={ky}
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
									useClassName="largeInput"
								/>
							))}
						</Grid>
						
						{values.owner_stations.length > 0 &&
							<Grid item className={classes.gridColumn}>
								<>
									<h1 className={classes.gridColumnHead}>Stations Owned</h1>
									{values["owner_stations"].map((asta,i) => (
										<React.Fragment key={"sta"+i}>
											{["sid","name"].map(ky => (
												<TextField
													key={"sta"+i+ky}
													label={ky}
													value={values.owner_stations[i][ky]}
													margin="dense"
													variant="outlined"
													disabled={true}
												/>
											))}
											<br />
										</React.Fragment>
									))}
								</>
							</Grid>
						}
					</Grid>

					<Button variant="contained" type="submit" className={classes.submit}>
						Submit
					</Button>
					<Button variant="contained" className={classes.submit} onClick={handleDeleteOwner}>
						Delete owner
					</Button>
				</form>
			}

			{jsonResults.length > 0 &&
				<div className={classes.verificationBox}>
					<span>{jsonResults}</span>
				</div>
			}
			
			{showDeleteAlert &&
				<DeleteAlertDialog
					dialogTitle = "Are you sure you want to delete this owner?"
					dialogContent = "Deleting an owner is not undoable."
					handleClose = {() => setShowDeleteAlert(false)}
					handleConfirm = {handleDeleteConfirm}
				/>
			}
		</div>
	)
}