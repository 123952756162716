import React, { useState, useContext } from 'react'
import Button from '@mui/material/Button'
import Typography from "@mui/material/Typography";
import ViewStation from './ViewStation'
import QueryStation from './QueryStation'
import ViewOwner from './ViewOwner'
import QueryOwner from './QueryOwner'
import QueryAllStations from './QueryAllStations'
import ReadFile from './ReadFile'
import { StylesContext } from './App'

export default function Interface(props) {
	const { token, logout } = props;
	const classes = useContext(StylesContext)
	const [ showDisplay, setShowDisplay ] = useState("")
	const [ viewStation, setViewStation ] = useState(false)
	const [ viewStations, setViewStations ] = useState(false)
	const [ viewOwner, setViewOwner ] = useState(false)
	const [ di, setDi ] = useState(0)

 	return (
		<>
			<div style={{padding:"1em 1em 0 1em"}}>
				<Typography variant="h5">
					NEWA Station Metadata Manager
					<Button
						variant="outlined"
						size="small"
						className={classes.submit}
						style={{position:"absolute", right:"1em"}} 
						onClick={() => logout()}
					>
						Logout
					</Button>
					{!token && 
					<p>Authorizing...</p>
					}
				</Typography>
			</div>
			<div style={{padding:"1em 0 0 1em"}}>
				<Button variant="contained" className={classes.submit} onClick={() => setShowDisplay("addStation")}>
					Add new station
				</Button>
				<Button variant="contained" className={classes.submit} onClick={() => {setViewStation(false); setShowDisplay("viewStation")}}>
					View/edit station
				</Button>
				<Button variant="contained" className={classes.submit} onClick={() => setShowDisplay("addOwner")}>
					Add new owner
				</Button>
				<Button variant="contained" className={classes.submit} onClick={() => {setViewOwner(false); setShowDisplay("viewOwner")}}>
					View/edit owner
				</Button>
				<Button variant="contained" className={classes.submit} onClick={() => {setViewStations(false); setShowDisplay("viewAllStations")}}>
					Stations in state
				</Button>
				<Button variant="contained" disabled className={classes.submit} onClick={() => {setDi(di + 1); setShowDisplay("viewFile")}}>
					Load stations from file
				</Button>
			</div>
			{showDisplay === "addStation" &&
				<ViewStation />
			}
			{showDisplay === "viewStation" &&
				<QueryStation viewStation={viewStation} setViewStation={setViewStation} />
			}
			{showDisplay === "addOwner" &&
				<ViewOwner />
			}
			{showDisplay === "viewOwner" &&
				<QueryOwner viewOwner={viewOwner} setViewOwner={setViewOwner} />
			}
			{showDisplay === "viewAllStations" &&
				<QueryAllStations viewStations={viewStations} setViewStations={setViewStations} />
			}
			{showDisplay === "viewFile" &&
				<ReadFile di={di} />
			}
 		</>
 	)
}
