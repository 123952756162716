import React, { useState, useEffect, useContext } from "react"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import useForm from "./useForm"
import NotesAdd from "./NotesAdd"
import NotesDelete from "./NotesDelete"
import ConnectForm from "./ConnectForm"
import RenderTextField from "./RenderTextField"
import RenderDateField from "./RenderDateField"
import DeleteAlertDialog from "./DeleteAlertDialog"
import { validationSchema } from "./validationSchema"
import { fieldRefs } from "./fieldRefs"
import { defineSchema, updateValues } from "./ViewRoutines"
import { StylesContext } from "./App"
import { client } from "./client";
import { CREATE_STATION, DELETE_STATION, UPDATE_STATION, CREATE_OWNER, OWNER_BY_ID } from "./queries"

export default function ViewStation(props) {
	// Define queries
	const {results=null } = props;
	const classes = useContext(StylesContext);
	const [ addedStationData, setAddedStationData ] = useState("");
	const [ jsonResults, setJsonResults ] = useState("")
	const [ showDeleteAlert, setShowDeleteAlert ] = useState(false)

	// Define value and error schema
	const document_id = results ? results.id : ""
	let [valueSchema, errorSchema] = defineSchema(results)
	
	// used in owner area
	const [ ownerDisabled, setOwnerDisabled ] = useState(true)
	const [ showDisconnectAlert, setShowDisconnectAlert ] = useState(false)

	// groupings of fields in the grid
	const stationFields = ["station_nid", "station_network", "station_name","station_state", "station_active_status", "station_disconnect_date", "station_sid"]
	const notesFields = ["noteText", "entryDate", "entryCreator"]
	const locationFields = ["loc_country", "loc_county", "loc_province", "loc_city", "loc_lat", "loc_lon", "loc_elevation", "loc_timezone",
				"loc_activation_date", "loc_replacement", "loc_replacement_newa_id"]
	const wxstaFields = ["station_brand", "station_rw_sn", "station_rw_id", "station_rw_model", "station_rw_comm", "station_rw_mac", 
				"station_rw_solrad_active", "station_rw_solrad_cap_off", "station_rw_upload_onemin", "station_on_sn"]
	const sensorFields = ["sensor_temp_air", "sensor_rh", "sensor_rainfall", "sensor_wetness", "sensor_solrad", "sensor_solrad_quality",
				"sensor_windspd", "sensor_windspd_height", "sensor_winddir"]
	const detailFields = ["depth", "cover"]
	const ownerFields = ["owner_name", "owner_phone", "owner_email", "owner_address", "owner_city", "owner_country", "owner_state",
				"owner_province", "owner_zip", "owner_affiliation", "owner_affiliation_display", "owner_affiliation_url", 
				"owner_affiliation_url_display", "owner_id"]
	const contactFields = ["maint1_name", "maint1_org", "maint1_phone", "maint1_email", 
				"maint2_name", "maint2_affiliation", "maint2_phone", "maint2_email"]   

	// FQL queries and actions
	const addStation = async(adddata) => {
        // setup and execute the query
        try {
            const q = CREATE_STATION(adddata);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
			setAddedStationData(data.data)
        } catch (error) {
            console.log('Database station addition error: '+ error);
			setAddedStationData(error.code);
        }
    };

	const addOwner = async(owndata) => {
        // setup and execute the query
        try {
            const q = CREATE_OWNER(owndata);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
			const changeGroup = {}
			ownerFields.forEach(element => {
				const val = data.data[element.replace("owner_","")];
				changeGroup[element] = !element.includes("display") ? val : (val ? 1 : (val === null ? "" : 0))
			})
			handleOnChangeGroup(changeGroup);
        } catch (error) {
            console.log('Database owner create error: ' + error);
        }
    };

	const deleteStation = async(id) => {
        // setup and execute the query
        try {
            const q = DELETE_STATION(id);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
			setJsonResults('Station deleted');
        } catch (error) {
            console.log('Database station delete error: '+ error);
			setJsonResults('Database station delete error');
        }
    };

	const updateStation = async(params) => {
		const { id, updata } = params;
        // setup and execute the query
        try {
            const q = UPDATE_STATION(id, updata);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
			setAddedStationData(data.data);
        } catch (error) {
            console.log('Database station update error: ' + error);
			setAddedStationData(error);
        }
    };

	const ownerById = async(id) => {
        // setup and execute the query
        try {
            const q = OWNER_BY_ID(id);
            const data = await client.query(q);
			if (data.hasOwnProperty("error")) {
				throw data.error
			}
            return data.data;
        } catch (error) {
            console.log('Database owner by id error: ' + error);
            return {};
        }
    };

	// form submit action
	function onSubmitForm() {
		const updatedValues = updateValues(values);
		const addOwnerInfo = async(updatedValues) => {
			const ownerObject = values.owner_id ? await ownerById(values.owner_id) : {}
			if (document_id) {
				updateStation({id: document_id, updata: {...updatedValues, owner: ownerObject}})
			} else {
				addStation({...updatedValues, owner: ownerObject})
			}
		}
		addOwnerInfo(updatedValues);
	}

	// setup form handling
	const { values, errors, handleOnChange, handleOnSubmit, handleOnChangeGroup } = useForm(
		valueSchema,
		errorSchema,
		validationSchema,
		onSubmitForm
	)

	// show results following addStation or updateStation mutation
	useEffect(() => {
		if (addedStationData) {
			setJsonResults("Submitted data: " + JSON.stringify(addedStationData, null, 2))
		}
	}, [addedStationData])

	function handleCreateOwner() {
		const owner_info = {
			name: values.owner_name, 
			phone: values.owner_phone, 
			email: values.owner_email, 
			address: values.owner_address, 
			city: values.owner_city, 
			country: values.owner_country,
			state: values.owner_state,
			province: values.owner_province, 
			zip: values.owner_zip, 
			affiliation: values.owner_affiliation, 
			affiliationDisplay: values.owner_affiliation_display === 1,
			affiliationUrl: values.owner_affiliation_url, 
			affiliationUrlDisplay: values.owner_affiliation_url_display === 1,
		};
		addOwner(owner_info);
		setOwnerDisabled(true)
  	}

	// disconnect owner button click event
	function handleDisconnectOwner() {
		setShowDisconnectAlert(true)
	}
	// disconnect confirmed
	function handleDisconnectConfirm() {
		if (document_id) {
			// remove owner from existing document
			updateStation({ id: document_id, updata: {owner: {}} });
		}
		// clear owner fields in form
		const changeGroup = {};
		ownerFields.forEach(element => {
			changeGroup[element] = !element.includes("display") ? "" : 0
		})
		handleOnChangeGroup(changeGroup);
		setShowDisconnectAlert(false);
	}

	// delete station button click event
	function handleDeleteStation() {
		setShowDeleteAlert(true)
	}
	// delete confirmed
	function handleDeleteConfirm() {
		deleteStation(document_id)
		setShowDeleteAlert(false)
	}

	return (
		<div style={{padding:"1em"}}>
			<form onSubmit={handleOnSubmit} noValidate>
				<Grid
					container
					direction="row"
					alignContent="center"
					justify="flex-start"
					alignItems="flex-start"
					spacing={1}
				>
					<Grid item className={classes.gridColumn}>
						<h1 className={classes.gridColumnHead}>Station Identification</h1>
						{stationFields.map(ky => (
							<React.Fragment key={ky}>
							{!ky.includes("date") && 
								<RenderTextField 
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
									disabled={(['station_nid','station_network'].includes(ky) && document_id) || ky==='station_sid'?true:false}
								/>
							}
							{ky.includes("date") && 
								<RenderDateField
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
								/>
							}
							</React.Fragment>
						))}

						<h1 className={classes.gridColumnHead}>Station Notes</h1>
						{values.notes.length > 0 &&
							values.notes.map((anote,i) => (
								<React.Fragment key={"note"+i}>
									{notesFields.map(ky => (
										<React.Fragment key={"note"+i+ky}>
											{!ky.includes("Date") &&
												<RenderTextField
													key={"note"+i+ky}
													field={ky+">notes>"+i}
													ovalue={values.notes[i][ky]}
													error={errors.notes}
													handleOnChange={handleOnChange}
													values={values}
												/>
											}
											{ky.includes("Date") &&
												<RenderDateField
													key={"note"+i+ky}
													field={ky+">notes>"+i}
													ovalue={values.notes[i][ky]}
													error={errors.notes}
													handleOnChange={handleOnChange}
													values={values}
													useClassName="smallDateInput"
												/>
											}
										</React.Fragment>
									))}
									<hr style={{borderTop:"1px solid darkgray"}}/>
								</React.Fragment>
							))
						}
						<NotesAdd 
							currentNotes={values.notes}
							handleChange={handleOnChange}
						/>
						{values.notes.length > 0 &&
							<NotesDelete 
								currentNotes={values.notes}
								handleChange={handleOnChange}
							/>
						}
					</Grid>

					<Grid item className={classes.gridColumn}>
						<h1 className={classes.gridColumnHead}>Location Information</h1>
						{locationFields.map(ky => (
							<React.Fragment key={ky}>
							{!ky.includes("date") && 
								<RenderTextField 
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
								/>
							}
							{ky.includes("date") && 
								<RenderDateField
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
								/>
							}
							</React.Fragment>
						))}
					</Grid>

					<Grid item className={classes.gridColumn}>
						<h1 className={classes.gridColumnHead}>Station Information</h1>
						{wxstaFields.map(ky => (
								<RenderTextField 
									key={ky}
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
								/>
						))}
					</Grid>

					<Grid item className={classes.gridColumn}>
						<h1 className={classes.gridColumnHead}>Sensor Information</h1>
						{sensorFields.map(ky => (
								<RenderTextField 
									key={ky}
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
								/>
						))}
						<RenderTextField 
									key={"sensor_temp_soil"}
									field={"sensor_temp_soil"}
									ovalue={values["sensor_temp_soil"]}
									error={errors["sensor_temp_soil"]}
									handleOnChange={handleOnChange}
									values={values}
						/>
						{values.sensor_temp_soil_detail &&
							values.sensor_temp_soil_detail.map((adetail,i) => (
							<React.Fragment key={"st"+i}>
								{detailFields.map(ky => (
									<RenderTextField
										key={"st"+i+ky}
										field={ky+">sensor_temp_soil_detail>"+i}
										ovalue={values.sensor_temp_soil_detail[i][ky] || ""}
										error={errors.sensor_temp_soil_detail}
										handleOnChange={handleOnChange}
										values={values}
									/>
								))}
							</React.Fragment>
							))
						}
						<RenderTextField 
									key={"sensor_moisture_soil"}
									field={"sensor_moisture_soil"}
									ovalue={values["sensor_moisture_soil"]}
									error={errors["sensor_moisture_soil"]}
									handleOnChange={handleOnChange}
									values={values}
						/>
						{values.sensor_moisture_soil_detail &&
							values.sensor_moisture_soil_detail.map((adetail,i) => (
							<React.Fragment key={"sm"+i}>
								{detailFields.map(ky => (
									<RenderTextField
										key={"sm"+i+ky}
										field={ky+">sensor_moisture_soil_detail>"+i}
										ovalue={values.sensor_moisture_soil_detail[i][ky] || ""}
										error={errors.sensor_moisture_soil_detail}
										handleOnChange={handleOnChange}
										values={values}
									/>
								))}
							</React.Fragment>
							))
						}
					</Grid>

					<Grid item className={classes.gridColumn}>
						<h1 className={classes.gridColumnHead}>Owner Information</h1>
						{ownerFields.map(ky => (
							<RenderTextField 
									key={ky}
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
									disabled={ky === "owner_id" ? true : ownerDisabled}
							/>
						))}

						{values.owner_id &&
							<div style={{textAlign:"center"}}>
								<Button variant="outlined" size="small" onClick={handleDisconnectOwner}>
									Disconnect owner
								</Button>
							</div>
						}

						{!values.owner_id &&
							<div style={{textAlign:"center"}}>
								{ownerDisabled &&
									<>
										<Button variant="outlined" size="small" style={{marginTop:"1em", marginBottom:"1em"}} onClick={() => setOwnerDisabled(false)}>
											Setup new owner
										</Button>
										<ConnectForm 
											updateStation={updateStation}
											ownerById={ownerById}
											handleOnChangeGroup={handleOnChangeGroup}
											ownerFields={ownerFields}
											document_id={document_id}
											sid={values.station_sid}
										/>
									</>
								}
								{!ownerDisabled &&
									<>
										<Button variant="outlined" size="small" style={{marginTop:"1em"}} onClick={handleCreateOwner}>
											Create
										</Button>
										<p style={{color:"red", width:"12em"}}>Click "Create" after entering information</p>
									</>
								}
							 </div>
						}
					</Grid>
					

					{(values.station_network === "newa" || values.station_network === "nwon") &&
						<Grid item className={classes.gridColumn}>
							<h1 className={classes.gridColumnHead}>Contact Information</h1>
							{contactFields.map(ky => (
								<RenderTextField 
									key={ky}
									field={ky}
									ovalue={values[ky]}
									error={errors[ky]}
									handleOnChange={handleOnChange}
									values={values}
								/>
							))}
						</Grid>
					}
				</Grid>

				<Button variant="contained" type="submit" className={classes.submit}>
					Submit
				</Button>
				{document_id && 
					<Button variant="contained" className={classes.submit} onClick={handleDeleteStation}>
						Delete station
					</Button>
				}
			</form>

			{jsonResults.length > 0 &&
				<div className={classes.verificationBox}>
					<span>{jsonResults}</span>
				</div>
			}

			{showDeleteAlert &&
				<DeleteAlertDialog
					dialogTitle = "Are you sure you want to delete this station?"
					dialogContent = "Deleting a station is not undoable."
					handleClose = {() => setShowDeleteAlert(false)}
					handleConfirm = {handleDeleteConfirm}
				/>
			}
			{showDisconnectAlert &&
				<DeleteAlertDialog
					dialogTitle = "Are you sure you want to disconnect this owner?"
					dialogContent = "This will disassociate the owner with this station."
					handleClose = {() => setShowDisconnectAlert(false)}
					handleConfirm = {handleDisconnectConfirm}
				/>
			}

		</div>
	)
}
