import React, { useState, useContext } from 'react'
import RenderDateField from './RenderDateField'
import { validatedate } from './validationSchema'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography';
import { StylesContext } from './App'

export default function NotesAdd(props) {
  const { currentNotes, handleChange } = props
  const classes = useContext(StylesContext)
  const today = new Date()
  const ymd = [today.getFullYear(), today.getMonth()+1, today.getDate()].join("-")
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState({entryDate:ymd, entryCreator:"", noteText:""})
  const [errors, setErrors] = useState({entryDate:"", entryCreator:"", noteText:""})

  const creatorList = [
    { value: "Olmstead", label: "Olmstead" },
    { value: "Eggleston", label: "Eggleston" },
    { value: "Knappenberger", label: "Knappenberger" },
    { value: "Noon", label: "Noon" },
    { value: "Newatech", label: "NEWA Tech" },
  ]

  function handleClickOpen() {
    setValues({entryDate:ymd, entryCreator:"", noteText:""})
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleOnChange(event) {
    const name = event.target.name
    const value = event.target.value
    if (name === "entryDate") {
      const goodDate = validatedate(value)
      setErrors({...errors, [name]: goodDate ? "" : "Invalid date - must be in the form yyyy-mm-dd"})
    }
		setValues({...values, [name]: value})
  }

  function handleOnSubmit() {
    if (errors.entryDate === "" && errors.entryCreator === "" && errors.noteText === "") {
      const newNote = [...currentNotes, {entryDate: values.entryDate, entryCreator: values.entryCreator, noteText: values.noteText}]
      handleChange({target: {name: "notes", value: newNote}})
      setOpen(false)
    }
  }

  return (
    <div style={{textAlign:"center", display:"inline-block"}}>
      <Button variant="outlined" size="small" onClick={handleClickOpen}>
        Add note
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Notes Addition</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={2}
          >
            <Grid item xs={6}>
              <RenderDateField
                field="entryDate>notes"
                ovalue={values.entryDate}
                error={errors.entryDate}
                handleOnChange={handleOnChange}
                classes={classes}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  id={"entryCreator"}
                  name={"entryCreator"}
                  value={values.entryCreator}
                  label={"Creator"}
                  margin="dense"
                  variant="outlined"
                  onChange={handleOnChange}
                  select={true}
                  className={classes.input}
              >
                  {creatorList.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                          {option.label}
                      </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <br />
            <Grid item xs={12}>
              <TextField
                  id={"noteText"}
                  name={"noteText"}
                  value={values.noteText}
                  label={"Note"}
                  margin="dense"
                  variant="outlined"
                  onChange={handleOnChange}
                  multiline={true}
                  fullWidth={true}
                  rows={4}
                  rowsmax={10}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOnSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        <Typography variant="caption" align="right" style={{paddingRight:"8px"}}>
          Main form must also be submitted
        </Typography>
      </Dialog>
    </div>
  )
}